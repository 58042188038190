import axios from 'axios'

const API = 'landing/footers'

const Categorias = {
    getFooters(params){
        return axios.get(`${API}/listar`,{params})
    },
    deleteFooters(params){
        return axios.delete(`${API}/eliminar`,{params})
    },
    actualizarPosicionFooter(payload){
        return axios.put(`${API}/actualizar-posicion`,payload)
    },
    actualizarColores(payload){
        return axios.post(`${API}/configurar-color`,payload)
    },
    guardarBloqueFooters(payload){
        return axios.post(`${API}/guardar`,payload)
    },
    editarBloqueFooters(payload){
        return axios.put(`${API}/editar`,payload)
    },
}

export default Categorias
