<template>
    <section class="section-footer">
        <div class="info-top bg-white text-general px-3 pt-3 pb-2">
            <div class="d-middle">
                <i class="icon-left-circle f-20 cr-pointer" @click="$router.push({name: 'admin.paginas-inicio'})" />
                <p class="f-18 f-500">Footer</p>
            </div>
            <div class="row">
                <div class="col-12 col-lg-7 mt-auto">
                    <p>Este footer es general y funcionará para todas las landing creadas</p>
                </div>
                <div class="col-12 col-lg-5">
                    <p>Seleccionar colores</p>
                    <div class="d-middle">
                        <div class="d-middle mr-2">
                            <p class="f-15 mr-2">Fondo</p>
                            <el-color-picker v-model="configuracion.background" popper-class="color-piker-footer" size="mini" @change="actualizarColor(121)" />
                        </div>
                        <div class="d-middle mr-2">
                            <p class="f-15 mr-2">Titulos</p>
                            <el-color-picker v-model="configuracion.titulos" popper-class="color-piker-footer" size="mini" @change="actualizarColor(122)" />
                        </div>
                        <div class="d-middle mr-2">
                            <p class="f-15 mr-2">Secciones</p>
                            <el-color-picker v-model="configuracion.textos" popper-class="color-piker-footer" size="mini" @change="actualizarColor(123)" />
                        </div>
                        <el-tooltip content="Agregar bloque" effect="light" :show-arrow="false" :offset="0">
                            <div class="wh-36px bg-general br-8 cr-pointer d-middle-center text-white ml-0 ml-lg-3" @click="addBlock">
                                <i class="icon-plus" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="footers.length" class="mt-5">
            <draggable :list="footers" handle=".handle" class="block-list d-flex scroll-none pb-3" @change="changePositionBlock">
                <div v-for="(data,index) in footers" :key="index" class="d-flex">
                    <div class="card-block mr-2">
                        <div class="main py-2 px-1">
                            <div class="d-flex">
                                <i class="icon-drag f-22 cr-pointer handle text-muted" />
                                <div class="flex-fill tres-puntos text-general">
                                    <div class="d-middle">
                                        <p class="f-14">Bloque {{ index+1 }}</p>
                                    </div>
                                    <p class="f-12 lh-15 tres-puntos"> {{ data.texto }} </p>
                                    <hr class="my-1" />
                                    <p v-for="(info,idx) in data.items" :key="idx" class="f-12 mb-2">
                                        <a :href="info.ruta" class="text-general py-1" target="_blank">
                                            {{ info.texto }}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <el-popover placement="bottom" effect="light" trigger="hover" popper-class="br-8 p-2" :visible-arrow="false">
                        <div class="row mx-0 p-2 item-popover" @click="openEditBlock(data, index)">
                            Editar
                        </div>
                        <div class="row mx-0 p-2 item-popover" @click="openDeleteBlock(data.id)">
                            Eliminar
                        </div>
                        <div slot="reference" class="btn-options wh-36px d-middle-center cr-pointer">
                            <i class="icon-dots-vertical" />
                        </div>
                    </el-popover>
                </div>
            </draggable>
        </div>
        <div v-else class="mt-5 text-center">
            <p class="f-16 text-general text-center w-206px mx-auto mb-3">Aun no se han creado bloques de información</p>
            <button class="btn btn-general w-137px h-36px" @click="addBlock">
                Crear bloque
            </button>
        </div>
        <!-- partials -->
        <modalCreateBlock ref="refModalCreateBlock" @update="listarFooters" />
        <modal-eliminar
        ref="refDeleteBlock"
        titulo="Eliminar bloque"
        mensaje="¿Desea eliminar este bloque?"
        @eliminar="eliminarFooter"
        />
    </section>
</template>

<script>
import Footer from "~/services/landing/landingFooter";
export default {
    components: {
        modalCreateBlock: () => import('./partials/modalCreateBlock'),
    },
    data(){
        return {
            configuracion: {
                background: "",
                titulos: "",
                textos: ""
            },
            footers: [],
            idFooter: null
        }
    },
    mounted(){
        this.listarFooters()
    },
    methods: {
        addBlock(){
            this.$refs.refModalCreateBlock.toggle();
        },
        openEditBlock(footer, index){
            this.$refs.refModalCreateBlock.toggle('editar',footer,index);
        },
        openDeleteBlock(idFooter){
            this.idFooter = idFooter;
            this.$refs.refDeleteBlock.toggle();
        },
        async listarFooters(){
            try {
                const {data} = await Footer.getFooters(this.id_pedido)
                this.footers = data.data.footers;
                this.configuracion = data.data.configuracion;
            } catch (e){
                this.error_catch(e)
            }
        },
        async eliminarFooter(){
            try {
                await Footer.deleteFooters({idFooter: this.idFooter})
                this.notificacion('Mensaje', 'eliminado exitoso', 'success')
                this.listarFooters()
                this.idFooter = null;
                this.$refs.refDeleteBlock.toggle();
            } catch (e){
                this.error_catch(e)
            }
        },
        async changePositionBlock(event){
            try {
                console.log(event);
                const params = {
                    antiguaPosicion: event.moved.oldIndex + 1,
                    nuevaPosicion: event.moved.newIndex + 1,
                    idFooter: event.moved.element.id,
                }
                
                await Footer.actualizarPosicionFooter(params)

                this.notificacion('Mensaje', 'Actualizado exitoso', 'success')
                this.listarFooters()
            } catch (e){
                this.error_catch(e)
            }
        },
        async actualizarColor(tipo){
            try {
                
                let color = ''
                switch (tipo){
                case 121:
                    color = this.configuracion.background;
                    break;
                case 122:
                    color = this.configuracion.titulos;
                    break;
                case 123:
                    color = this.configuracion.textos;
                    break;
                }

                await Footer.actualizarColores({tipo: tipo, color: color})
                this.notificacion('Mensaje', 'Color actualizado', 'success')
                
            } catch (e){
                this.error_catch(e)
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.section-footer{
	.info-top{
		box-shadow: 0px 1px 4px #00000014;
	}
	.block-list{
		gap: 22px;
		width: 100%;
		overflow: auto;
		overflow-x: auto;
		overflow-y: hidden;
		.card-block{
			.main{
				max-width: 200px;
				min-width: 200px;
				background: #FFFFFF;
				box-shadow: 0px 3px 6px #00000029;
				border-radius: 8px;
			}
		}
	}
	.wh-36px{
		max-width: 36px;
		min-width: 36px;
		min-height: 36px;
		max-height: 36px;
	}
	.btn-options{
		background: transparent linear-gradient(180deg, #FFFFFF 0%, #F3F5F7 100%) 0% 0% no-repeat padding-box;
		border-radius: 8px;
		border: 1px solid #CFD7DF;
	}
	.pl-32px{
		padding-left: 32px;
	}
}
.item-popover:hover{
    cursor: pointer;
    background-color: #F6F9FB;
}
.w-137px{
	width: 137px;
}
.w-206px{
	width: 206px;
}
.h-36px{
	height: 36px;
}
</style>